import React, { useState } from 'react'
import '../../assets/scss/Common.scss'
import '../../assets/scss/Home.scss'
import resultbg from '../../assets/images/result-bg.png'
import playagainbtn from '../../assets/images/play-agin-btn.png'
import getrewardbtn from '../../assets/images/get-reward-btn.png'
import gameruleshape from '../../assets/images/game-rule-shape.png'
import footerimg from '../../assets/images/common-footer.png'
import PlayAgain from '../../components/PlayAgain'
import Rewards from '../../components/Rewards'
import { useLocation } from 'react-router-dom'

const Result = () => {
  const location = useLocation();
  const { score } = location.state || 0;

  const [showPlayAgain, setShowPlayAgain] = useState(true);
  const [showGetRewards, setGetRewards] = useState(false);

  const showplayagain = () => {
    setShowPlayAgain(true);
    setGetRewards(false);
  }
  const showrewards = () => {
    setShowPlayAgain(false);
    setGetRewards(true);
  }

  return (
    <div className='container'>
      {!showPlayAgain && !showGetRewards ? (
        <div className='result-sec'>
          {/* <div className='result-bg-sec'>
            <img src={resultbg} alt="Result Background" />
            <h3>{score} Pts.</h3>
          </div>
          <div className='result-btns'>
            <button onClick={showplayagain}><img src={playagainbtn} alt="Play Again Button" /></button>
            <button onClick={showrewards}><img src={getrewardbtn} alt="Get Reward Button" /></button>
          </div>
          <img src={gameruleshape} /> */}
        </div>
      ) : showPlayAgain ? (
        <div className='replay-sec'>
          <PlayAgain score={score} />
          <img src={gameruleshape} className='reply-bottom-frame' />
        </div>
      ) : (
        <>
          <div className='my-rewards-sec'>
            <div>
              <Rewards />
              <div className='result-btns'>
                <button onClick={showplayagain}><img src={playagainbtn} alt="Play Again Button" /></button>
              </div>
            </div>
            <img src={gameruleshape} />
          </div>

        </>
      )}
      <img src={footerimg} className='footerimg' />
    </div>
  );
};

export default Result;
