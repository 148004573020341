import React, { useEffect, useState, useRef } from 'react';

const CountdownTimer = ({ totalSeconds }) => {
    const [seconds, setSeconds] = useState(totalSeconds);
    const [percent, setPercent] = useState(100);
    const intervalRef = useRef(null);

    useEffect(() => {
        // Initial percentage setup to trigger immediate animation
        setPercent((totalSeconds / totalSeconds) * 100);

        intervalRef.current = setInterval(() => {
            setSeconds(prevSeconds => {
                const newSeconds = prevSeconds - 1;
                if (newSeconds < 0) {
                    clearInterval(intervalRef.current);
                    return 0;
                }
                setPercent((newSeconds / totalSeconds) * 100);
                return newSeconds;
            });
        }, 1000);

        return () => clearInterval(intervalRef.current);
    }, [totalSeconds]);

    return (
        <div className="countdown-timer">
            <h3>PREPARE YOURSELF</h3>
            <div className="circle">
                <svg>
                    <circle cx="100" cy="100" r="80"></circle>
                    <circle cx="100" cy="100" r="80" style={{ strokeDashoffset: 485 - (485 * percent) / 100 }}></circle>
                </svg>
                <div className="timer-text">{seconds}</div>
            </div>
        </div>
    );
};

export default CountdownTimer;
