import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Loader from '../../components/Loader';
import StartScreen from '../../components/startscreen'
import spalashbg from '../../assets/images/splash-screen.png'
import '../../assets/scss/Common.scss'
import '../../assets/scss/Home.scss'
import '../../assets/scss/Loader.scss'
import startgamebtn from '../../assets/images/play-btn.png';

const Home = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading delay
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);
  return (
    <div className='container'>
      <div className='loading-sec'>
        <img src={spalashbg} />
        {loading ? <div className='loadingbar-sec'>
          <Loader />
        </div> : <div className='btn-sec'>
          <a href='/gameplay' className='startgame-btn'> <img src={startgamebtn} /></a>
        </div>}

      </div>
    </div>
  )
}
export default Home
