import React, { useState } from 'react';
import rewardsimg from '../assets/images/rewards-img.png'
import playagainbtn from '../assets/images/play-agin-btn.png';
import { useNavigate } from 'react-router-dom';

const Rewards = ({ score }) => {
    const [name, setName] = useState('');
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (name) {
            // Retrieve existing entries from localStorage
            const existingEntries = JSON.parse(localStorage.getItem('scoreEntries')) || [];
            const newEntry = { name, score };
            const updatedEntries = [...existingEntries, newEntry];
            localStorage.setItem('scoreEntries', JSON.stringify(updatedEntries));
            console.log('New entry saved:', newEntry);
            navigate('/leaderboard', { state: { score } });
        }
    };

    return (
        <div className='reward-sec'>
            <img src={rewardsimg} />
        </div>
    );
};

export default Rewards;
