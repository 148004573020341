import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import '../../assets/scss/Common.scss'
import '../../assets/scss/Home.scss'
import GameRules from '../../components/GameRules'
import CountdownTimer from '../../components/CountdownTimer'
import gameruleshape from '../../assets/images/game-rule-shape.png'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import pose1 from '../../assets/images/pose-1.png'
import pose2 from '../../assets/images/pose-2.png'
import pose3 from '../../assets/images/pose-3.png'
import pose4 from '../../assets/images/pose-4.png'
import pose5 from '../../assets/images/pose-5.png'

import blue_pose1 from '../../assets/images/blue-pose-1.png'
import blue_pose2 from '../../assets/images/blue-pose-2.png'
import blue_pose3 from '../../assets/images/blue-pose-3.png'
import blue_pose4 from '../../assets/images/blue-pose-4.png'
import blue_pose5 from '../../assets/images/blue-pose-5.png'

import yellow_pose1 from '../../assets/images/yellow-pose-1.png'
import yellow_pose2 from '../../assets/images/yellow-pose-2.png'
import yellow_pose3 from '../../assets/images/yellow-pose-3.png'
import yellow_pose4 from '../../assets/images/yellow-pose-4.png'
import yellow_pose5 from '../../assets/images/yellow-pose-5.png'

import pointsimg from '../../assets/images/points-img.png'
import gameplaybottombar from '../../assets/images/gameplay-bottom-bar.png'
import footerimg from '../../assets/images/common-footer.png'
import Roundtimer from '../../components/Roundtimer'
import PoseDetecter from '../../components/PoseDetector'



const Gameplay = () => {
  const [score, setScore] = useState(0);
  const [remainingTime, setRemainingTime] = useState(30);
  const [showGameRules, setShowGameRules] = useState(true);
  const [showCountdownTimer, setShowCountdownTimer] = useState(false);
  const [message, setMessage] = useState('');
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(1);
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef(null);
  const timerRef = useRef(null);
  const aPoses = [blue_pose1, blue_pose2, blue_pose3, blue_pose4, blue_pose5];
  const yellowPoses = [yellow_pose1, yellow_pose2, yellow_pose3, yellow_pose4, yellow_pose5];


  const [allPose, setAllPose] = useState([
    { name: 'Pose 1', value: '', id: 0 },
    { name: 'Pose 2', value: '', id: 1 },
    { name: 'Pose 3', value: '', id: 2 },
    { name: 'Pose 4', value: '', id: 3 },
    { name: 'Pose 5', value: '', id: 4 },
  ]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowGameRules(false);
      setShowCountdownTimer(true);
    }, 3000);
    const timer2 = setTimeout(() => {
      setShowCountdownTimer(false);
      startVideo();  // Start the video here
    }, 9000);
    if (remainingTime <= 0) {
      navigate('/result', { state: { score } });
    }

    return () => {
      clearTimeout(timer);
      clearTimeout(timer2);
    }
  }, [remainingTime]);

  // Find the current active slide (which is about to change)
  const activeSlide = document.querySelector('.slick-current');

  const resetCountdown = (currentslidescore) => {
    // Counter to track how many times '0' has been selected
    let zeroCount = 0;

    setCountdown(1);
    if (timerRef.current) {
      clearInterval(timerRef.current);
      console.log('3 second countdown reset');
    }
    timerRef.current = setInterval(() => {
      setCountdown((prev) => (prev > 0 ? prev - 1 : 1));

      if (activeSlide) {
        const activeImg = activeSlide.querySelector('img');
        if (activeImg) {
          console.log('Current slide img element:', activeImg);
          console.log('Current slide pose:', activeImg.dataset.pose);

          // Log the alt tag of the image
          console.log('Current slide alt text:', activeImg.alt);

          // Check if the pose was correct and update score
          const correctPose = allPose.find(item => {
            console.log('Current item:', item);
            return item.name === activeImg.alt && item.value === 'True';
          });

          if (correctPose) {
            console.log('%cCorrect pose detected', 'color: green');
            currentslidescore += 10;
            if (currentslidescore > 10) {
              // currentslidescore = 0;
              return;
            }
            updateRightImg();
            setScore(score + currentslidescore);
          } else {
            let array = [0, 1];

            // Choose a random index (0 or 1)
            let index = Math.floor(Math.random() * 2);

            // Take the value of the chosen element (0 or 1)
            let selectedValue = array[index];

            // If selectedValue is 0, increment zeroCount
            if (selectedValue === 0) {
              zeroCount++;
            } else {
              // Reset zeroCount if selectedValue is 1
              zeroCount = 0;
            }

            // If zeroCount is 4 or more, update the score
            if (zeroCount >= 1) {
              console.log('Updating score due to 0 being selected 4 times in a row');
              currentslidescore += 10;
              if (currentslidescore > 10) {
                // currentslidescore = 0;
                return;
              }
              updateRightImg();
              setScore(score + 10);
            }
          }
        }
      }
      console.log('Current slide score:', currentslidescore);
    }, 400);
  };
  const updateRightImg = () => {
    console.log("updated", document.getElementById('current-pose').alt);
    const nPoseNumber = document.getElementById('current-pose').alt.split(' ')[1];
    console.log("nPoseNumber", nPoseNumber);
    document.getElementById('current-pose').src = aPoses[nPoseNumber - 1];
    // document.getElementById('current-pose').src = ``;
  }

  useEffect(() => {
    let currentslidescore = 0;
    const centerSlide = document.querySelector('.slick-current + div');
    if (centerSlide) {
      const centerImage = centerSlide.querySelector('img');
      const nPoseNumber = centerImage.alt.split(' ')[1];
      document.getElementById('current-pose').src = yellowPoses[nPoseNumber - 1];
      document.getElementById('current-pose').alt = centerImage.alt;
    }
    resetCountdown(currentslidescore);
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
        currentslidescore = 0
      }
    };
  }, [currentSlide]);

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: remainingTime > 0,
    autoplaySpeed: 1000,
    cssEase: "linear",
    beforeChange: (oldIndex, newIndex) => {
      console.log(`Slide changing from ${oldIndex} to ${newIndex}`);
      resetCountdown();


    },
    afterChange: (currentSlide) => {
      console.log(`Slide changed to: `, currentSlide);
      console.log(remainingTime);
      setCurrentSlide(currentSlide);

      if (remainingTime === 6) {
        navigate('/result', { state: { score } });
      }
    }
  };



  async function requestCameraPermission() {
    try {
      await navigator.permissions.query({ name: 'camera' });
      return true;
    } catch (error) {
      console.error('Error querying camera permission:', error);
      return false;
    }
  }

  async function startVideo() {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      console.error('getUserMedia is not supported in this environment');
      return;
    }

    try {
      const permissionGranted = await requestCameraPermission();
      if (!permissionGranted) {
        console.error('Camera permission was denied');
        return;
      }

      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.onloadedmetadata = () => {
          videoRef.current.play();
        };
      }
    } catch (err) {
      console.error('Error accessing media devices:', err);
    }
  }

  return (
    <div className='container'>

      <div className='game-rules-main-sec'>
        {showGameRules && <GameRules />}
        {showCountdownTimer && <CountdownTimer totalSeconds={5} />}
        {showCountdownTimer || showGameRules ? <img className='game-rule-shape-img' src={gameruleshape} alt="Game rule shape" /> : null}
        {!showCountdownTimer && !showGameRules ?
          <div>
            {message !== '' ? <p className='error-msg'>{message}</p> : null}

            <div className='gameplay-header'>
              <div className='roundtimer-sec'>
                <Roundtimer remainingTime={remainingTime} setRemainingTime={setRemainingTime} />
              </div>
              <div className='middle-header'>
                {/* <h3>Hold Your Position: <span>{countdown}s</span></h3> */}
                <h3> </h3>
              </div>
            </div>
            <div className="exercise-slider">
              <Slider ref={sliderRef}  {...settings}>
                <div>
                  <div className='exercise-slide'>
                    <img src={pose2} alt="Pose 2" />
                  </div>
                </div>
                <div>
                  <div className='exercise-slide'>
                    <img src={pose5} alt="Pose 5" />
                  </div>
                </div>
                <div>
                  <div className='exercise-slide'>
                    <img src={pose3} alt="Pose 3" />
                  </div>
                </div>
                <div>
                  <div className='exercise-slide'>
                    <img src={pose1} alt="Pose 1" />
                  </div>
                </div>
                <div>
                  <div className='exercise-slide'>
                    <img src={pose4} alt="Pose 4" />
                  </div>
                </div>
              </Slider>
            </div>
            {remainingTime >= 0 ?
              <div className='canvas-main-sec'>
                <PoseDetecter allPose={allPose} setAllPose={setAllPose} />
                <div className='current-pose-sec'>
                  <img id='current-pose' src={pose5} alt="Pose 5" />
                </div>
                <div className='points-sec'>
                  <img src={pointsimg} alt="Points" />
                  <h4 className='score-count'>{score}</h4>
                </div>
                <img src={gameplaybottombar} className='gameplaybottombar' />
              </div>
              : null}
            <img src={footerimg} className='footerimg' alt="Footer" />
          </div>
          : null}
      </div>
    </div>
  );
};

export default Gameplay;
