import React, { useEffect, useRef, useState } from 'react';
import * as tmPose from '@teachablemachine/pose';
import '../../assets/scss/Common.scss';
import '../../assets/scss/Home.scss';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import PoseEstimation from '../../components/PoseDetector';

const Test = () => {
  const URL = './my_model/';
  const canvasRef = useRef(null);
  const webcamRef = useRef(null);
  const labelContainerRef = useRef(null);
  const [model, setModel] = useState(null);
  const [maxPredictions, setMaxPredictions] = useState(0);

  useEffect(() => {
    const init = async () => {
      const modelURL = 'https://game.yudiz.com/game-zone/model.json';
      const metadataURL = 'https://game.yudiz.com/game-zone/metadata.json';

      const loadedModel = await tmPose.load(modelURL, metadataURL);
      setModel(loadedModel);
      setMaxPredictions(loadedModel.getTotalClasses());

      const size = 600;
      const flip = true;
      const webcam = new tmPose.Webcam(size, size, flip);
      await webcam.setup();
      await webcam.play();

      webcamRef.current = webcam;
      window.requestAnimationFrame(loop);

      const canvas = canvasRef.current;
      if (canvas) {
        canvas.width = size;
        canvas.height = size;
      }

      if (labelContainerRef.current) {
        labelContainerRef.current.innerHTML = '';
        for (let i = 0; i < loadedModel.getTotalClasses(); i++) {
          labelContainerRef.current.appendChild(document.createElement('div'));
        }
      }
    };

    init();
  }, []);

  // useEffect(async () => {
  //   // const size = 600;
  //   // const flip = true;
  //   // const webcam = new tmPose.Webcam(size, size, flip);
  //   // await webcam.setup();
  //   // await webcam.play();

  //   // webcamRef.current = webcam;
  //   // window.requestAnimationFrame(loop);

  //   // const canvas = canvasRef.current;
  //   // if (canvas) {
  //   //   canvas.width = size;
  //   //   canvas.height = size;
  //   // }
  // }, [])

  const loop = async () => {
    if (webcamRef.current && model) {
      webcamRef.current.update();
      await predict();
      window.requestAnimationFrame(loop);
    }
  };

  const predict = async () => {
    const { pose, posenetOutput } = await model.estimatePose(webcamRef.current.canvas);
    const prediction = await model.predict(posenetOutput);

    for (let i = 0; i < maxPredictions; i++) {
      const span = document.createElement('span');
      const predictValue = prediction[i].probability.toFixed(2);
      span.innerHTML = predictValue > 0.85 ? 'True' : 'False';
      span.style.color = predictValue > 0.85 ? 'green' : 'red';

      const img = document.createElement('img');
      img.src = `${prediction[i].className}.png`;
      img.alt = prediction[i].className;

      const classPrediction = ' ';
      if (labelContainerRef.current && labelContainerRef.current.childNodes[i]) {
        labelContainerRef.current.childNodes[i].innerHTML = classPrediction;
        labelContainerRef.current.childNodes[i].appendChild(img);
        labelContainerRef.current.childNodes[i].appendChild(span);
      }
    }

    drawPose(pose);
  };

  const drawPose = (pose) => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (webcamRef.current.canvas && ctx) {
        ctx.drawImage(webcamRef.current.canvas, 0, 0);
        if (pose) {
          const minPartConfidence = 0.5;
          tmPose.drawKeypoints(pose.keypoints, minPartConfidence, ctx);
          tmPose.drawSkeleton(pose.keypoints, minPartConfidence, ctx);
        }
      }
    }
  };

  return (
    <>
      <PoseEstimation />
      <h1>Test Page</h1>
      {/* <div className="canvas-parent">
        <div>
          <canvas id="canvas" ref={canvasRef}></canvas>
        </div>
        <div id="label-container" ref={labelContainerRef}></div>
      </div> */}
    </>
  );
};

export default Test;
