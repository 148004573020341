import React, { useEffect, useRef, useState } from 'react';
import * as tmPose from '@teachablemachine/pose';

const PoseEstimation = ({ allPose, setAllPose }) => {
    const URL = "./my_model/";
    const canvasRef = useRef(null);
    const webcamRef = useRef(null);
    const labelContainerRef = useRef(null);
    const [model, setModel] = useState(null);
    const [isModelReady, setIsModelReady] = useState(false);
    const [maxPredictions, setMaxPredictions] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');
    const [isMounted, setIsMounted] = useState(true);


    useEffect(() => {
        setIsMounted(true);
        return () => setIsMounted(false);
    }, []);

    useEffect(() => {
        const loadModel = async () => {
            try {
                console.log('Loading model...');
                const modelURL = "https://game.yudiz.com/game-zone/model.json";
                const metadataURL = "https://game.yudiz.com/game-zone/metadata.json";

                const loadedModel = await tmPose.load(modelURL, metadataURL);
                setModel(loadedModel);
                setMaxPredictions(loadedModel.getTotalClasses());
                setIsModelReady(true);
            } catch (error) {
                console.error('Error loading the model:', error);
                setErrorMessage('Could not load the model. Please try again later.');
            }
        };

        loadModel();
    }, []);

    useEffect(() => {
        if (!isModelReady) return;

        let animationFrameId;

        const setupWebcamAndStartLoop = async () => {
            try {
                console.log('Setting up webcam...');
                const size = window.innerWidth;
                const flip = true;
                const webcam = new tmPose.Webcam(size, size, flip);

                await webcam.setup();
                await webcam.play();
                webcamRef.current = webcam;
                console.log('Webcam setup complete.');

                console.log('Setting up canvas...');
                const canvas = canvasRef.current;
                if (canvas) {
                    canvas.width = size;
                    canvas.height = window.innerHeight / 1.7;
                }

                console.log('Setting up label container...');
                if (labelContainerRef.current) {
                    labelContainerRef.current.innerHTML = "";
                    for (let i = 0; i < model.getTotalClasses(); i++) {
                        labelContainerRef.current.appendChild(document.createElement("div"));
                    }
                }

                console.log('Starting loop...');
                const loopFunction = async () => {
                    if (webcamRef.current && model && isMounted) {
                        webcamRef.current.update();
                        await predict();
                        animationFrameId = window.requestAnimationFrame(loopFunction);
                    }
                };
                loopFunction();
            } catch (error) {
                console.error('Error initializing the webcam:', error);
                setErrorMessage('Could not open your camera. You may have denied access or there may be an issue with your camera.');
            }
        };

        setupWebcamAndStartLoop();

        return () => {
            if (webcamRef.current) {
                webcamRef.current.stop();
            }
            if (animationFrameId) {
                cancelAnimationFrame(animationFrameId);
            }
        };
    }, [isModelReady, isMounted]);

    const predict = async () => {
        if (!model || !webcamRef.current || !isMounted) return;

        const { pose, posenetOutput } = await model.estimatePose(webcamRef.current.canvas);
        const prediction = await model.predict(posenetOutput);

        for (let i = 0; i < maxPredictions; i++) {
            const span = document.createElement("span");
            const predictValue = +(prediction[i].probability.toFixed(2));

            setAllPose(prevAllPose => {
                const newAllPose = [...prevAllPose];
                newAllPose[i] = {
                    ...newAllPose[i],
                    value: predictValue > 0.95 ? "True" : "False"
                };
                return newAllPose;
            });

            span.innerHTML = predictValue > 0.95 ? "True" : "False";
            span.style.color = predictValue > 0.95 ? "green" : "red";

            // const img = document.createElement("img");
            // img.src = `${prediction[i].className}.png`;
            // img.alt = prediction[i].className;

            const classPrediction = " ";
            if (labelContainerRef.current && labelContainerRef.current.childNodes[i]) {
                labelContainerRef.current.childNodes[i].innerHTML = classPrediction;
                // labelContainerRef.current.childNodes[i].appendChild(img);
                labelContainerRef.current.childNodes[i].appendChild(span);
            }
        }

        drawPose(pose);
    };

    const drawPose = (pose) => {
        const canvas = canvasRef.current;
        if (!canvas) return;
        const ctx = canvas.getContext("2d");
        if (webcamRef.current?.canvas && ctx) {
            ctx.drawImage(webcamRef.current.canvas, 0, 0);
            if (pose) {
                // const minPartConfidence = 0.5;
                // tmPose.drawKeypoints(pose.keypoints, minPartConfidence, ctx);
                // tmPose.drawSkeleton(pose.keypoints, minPartConfidence, ctx);
            }
        }
    };

    return (
        <div className="canvas-parent">
            {errorMessage && <div className="error">{errorMessage}</div>}
            <div>
                <canvas id="canvas" ref={canvasRef}></canvas>
            </div>
            <div id="label-container" ref={labelContainerRef}></div>
        </div>
    );
};

export default PoseEstimation;