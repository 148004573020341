// import React, { useCallback, useState } from 'react';
// import playagainbtn from '../assets/images/play-agin-btn.png';
// import userinput from '../assets/images/userinput.png'
// import { useNavigate } from 'react-router-dom';
// import resultbg from '../assets/images/result-bg.png'
// import resultbg_1 from '../assets/images/result-bg-1.png'
// import resultbg_2 from '../assets/images/result-bg-2.png'
// import gameruleshape from '../assets/images/game-rule-shape.png'
// import getrewardbtn from '../assets/images/get-reward-btn.png'


// const PlayAgain = ({ score }) => {
//     // const [name, setName] = useState('');
//     let sName = 'Guest';
//     const [rewards, setRewards] = useState(false);
//     const navigate = useNavigate();
//     let timeOut, newTime;

//     const handleTimeout = useCallback(() => {
//         console.count('handleTimeout');
//         timeOut = setTimeout(() => {
//             if (!rewards) {
//                 // setName('Guest');
//                 updateLocalStorage();
//                 // clearTimeout(timeOut);
//                 // setRewards(true);
//             }
//             navigate('/', { state: { score } });
//             return;
//         }, 30000)
//     }, [])
//     handleTimeout();
//     const updateLocalStorage = async () => {
//         const existingEntries = JSON.parse(localStorage.getItem('scoreEntries')) || [];
//         const newEntry = { sName, score };
//         existingEntries.push(newEntry)
//         await localStorage.setItem('scoreEntries', JSON.stringify(existingEntries));
//     }
//     const handleSubmit = (e) => {
//         e.preventDefault();
//         // console.log('name before', name)
//         clearTimeout(timeOut);
//         // if (s) {
//         // console.log('name after', name)
//         // Retrieve existing entries from localStorage
//         if (!rewards) {
//             console.log('here')
//             updateLocalStorage();
//             setRewards(!rewards);
//             // handleTimeout();

//             newTime = setTimeout(() => {
//                 navigate('/', { state: { score } });
//                 return;
//             }, 10000)
//             return;
//         }
//         // rewards ? navigate('/leaderboard') : null;
//         // navigate('/leaderboard', { state: { score } });
//         // navigate('/leaderboard', { state: { score } });

//         // }
//     };
//     const handlePlayAgain = (e) => {
//         e.preventDefault();
//         clearTimeout(newTime);
//         console.log('else')
//         navigate('/');
//     };
//     const handleOnChange = (e) => {
//         sName = e.target.value;
//     }
//     const getResultBackground = () => {
//         if (score < 200) {
//             return resultbg_1;
//         } else if (score >= 200 && score <= 250) {
//             return resultbg_2;
//         } else if(score > 250){
//             return resultbg;
//         }
//     };

//     return (
//         <>
//             {!rewards ? (
//                 <div className='playagaon-sec'>
//                     <img src={userinput} />
//                     <form onSubmit={(e) => handleSubmit(e)} >
//                         <input
//                             type='text'
//                             placeholder='Name'
//                             defaultValue={sName}
//                             onChange={(e) => handleOnChange(e)}
//                             // required
//                             autoFocus // Add autoFocus attribute
//                         />
//                         <button type='submit'></button>
//                     </form>
//                 </div>
//             ) : (
//                 <div className='result-sec'>
//                     <div className='result-bg-sec'>
//                         <img src={getResultBackground()} alt="Result Background" />
//                         <h3>{score} Pts.</h3>
//                     </div>
//                     <div className='result-btns'>
//                         <button onClick={handlePlayAgain}><img src={playagainbtn} alt="Play Again Button" /></button>
//                         <button onClick={() => rewards && navigate('/leaderboard', { state: { score } })}><img src={getrewardbtn} alt="Get Reward Button" /></button>
//                     </div>
//                     {/* <img src={gameruleshape} /> */}
//                 </div >
//             )}
//         </>
//     );
// };

// export default PlayAgain;
import React, { useState } from 'react';
import playagainbtn from '../assets/images/play-agin-btn.png';
import userinput from '../assets/images/userinput.png'
import { useNavigate } from 'react-router-dom';
import resultbg from '../assets/images/result-bg.png'
import resultbg_1 from '../assets/images/result-bg-1.png'
import resultbg_2 from '../assets/images/result-bg-2.png'
import getrewardbtn from '../assets/images/get-reward-btn.png'

const PlayAgain = ({ score }) => {
    let sName = 'Guest';
    const [showForm, setShowForm] = useState(false);
    const navigate = useNavigate();

    const getResultBackground = () => {
        if (score < 200) {
            return resultbg_1;
        } else if (score >= 200 && score <= 250) {
            return resultbg_2;
        } else if(score > 250){
            return resultbg;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const existingEntries = JSON.parse(localStorage.getItem('scoreEntries')) || [];
        const newEntry = { sName, score };
        existingEntries.push(newEntry);
        localStorage.setItem('scoreEntries', JSON.stringify(existingEntries));
        navigate('/leaderboard', { state: { score } });
    };

    const handlePlayAgain = (e) => {
        e.preventDefault();
        navigate('/');
    };

    const handleOnChange = (e) => {
        sName = e.target.value;
    }

    return (
        <>
            {!showForm ? (
                <div className='result-sec'>
                    <div className='result-bg-sec'>
                        <img src={getResultBackground()} alt="Result Background" />
                        <h3>{score} Pts.</h3>
                    </div>
                    <div className='result-btns'>
                        <button onClick={handlePlayAgain}><img src={playagainbtn} alt="Play Again Button" /></button>
                        <button onClick={() => setShowForm(true)}><img src={getrewardbtn} alt="Get Reward Button" /></button>
                    </div>
                </div>
            ) : (
                <div className='playagaon-sec'>
                    <img src={userinput} />
                    <form onSubmit={handleSubmit}>
                        <input
                            type='text'
                            placeholder='Name'
                            defaultValue={sName}
                            onChange={handleOnChange}
                            autoFocus
                        />
                        <button type='submit'></button>
                    </form>
                </div>
            )}
        </>
    );
};

export default PlayAgain;
