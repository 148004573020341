// Loader.js
import React from 'react';
import '../assets/scss/Loader.scss'

const Loader = () => {
    return (
        <div className="loader-container">
            <div className="loader">
                <div className="loader-bar"></div>
                <div className="loader-text">LOADING</div>
            </div>
        </div>
    );
};

export default Loader;
