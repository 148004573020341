
import React from 'react';
import gamerulesbg from '../assets/images/gamerulesbg.png'

const GameRules = () => {
    return (
        <div className='game-rules-sec'>
            <img className='game-rule-bg' src={gamerulesbg} />
            <div className='game-rules-info'>
                <ul>
                    <li>Stand in front of the kiosk camera and make sure you are fully visible in the viewfinder frame.</li>
                    <li>The game lasts for 1 minute.</li>
                    <li>A pose will be shown on the screen with an icon.</li>
                    <li>Replicate the pose as accurately as possible.</li>
                    <li>Hold the pose still for 3 seconds.</li>
                    <li>Each correctly replicated pose earns you 10 points.</li>
                    <li>Try to replicate as many poses as possible within the time limit.</li>
                    <li>At the end of the game, your final score will be displayed.</li>
                    <li>Click the "Play Again" button if you want to enter your name and try again.</li>
                    <li>Click the "Reward" button to see your reward</li>
                </ul>
            </div>
        </div>
    );
};

export default GameRules;
