
import React, { useEffect, useState } from 'react'
import '../../assets/scss/Common.scss'
import '../../assets/scss/Home.scss'
import { useLocation } from 'react-router-dom';
import highscorebg from '../../assets/images/higest-score-bg.png'
import { useNavigate } from 'react-router-dom'

const Leaderboard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { score } = location.state;
  const [entries, setEntries] = useState([]);
  const [highestScore, setHighestScore] = useState(0);
  const [highestScoreName, setHighestScoreName] = useState('');
  let timeOut;
  const handleTimeout = () => {
    timeOut = setTimeout(() => {
      playagaingame();
      return;
    }, 10000)
  }
  handleTimeout();
  useEffect(() => {
    const storedEntries = JSON.parse(localStorage.getItem('scoreEntries')) || [];
    const sortedEntries = storedEntries.sort((a, b) => b.score - a.score);
    if (sortedEntries.length > 0) {
      setHighestScore(sortedEntries[0].score);
      setHighestScoreName(sortedEntries[0].name);
    }
    setEntries(sortedEntries.slice(0, 10));
  }, []);
  const playagaingame = () => {
    clearTimeout(timeOut);
    navigate('/', { state: { score } });
  }

  return (
    <div className='container'>
      <div className='leaderboard-sec'>
        <div className='highest-socre-sec'>
          <h6>Score of the day</h6>
          <img src={highscorebg} />
          <span className='highest-score'><span>{highestScore}</span> Points </span>
        </div>
        <div className='highestscore-name'>
          <span>{highestScoreName}</span>
        </div>
        {entries.length > 0 ? (
          <div className='ranking-list-sec'>
            <ul className='ranking-list'>
              {entries.map((entry, index) => (
                console.log(entry),
                <li key={index}>
                  <span className='sr-no'>{index + 1}</span>
                  <span className='player-name'>{entry.sName}</span>
                  <span className='score'>{entry.score}</span>
                </li>
              ))}
            </ul>
            <button onClick={playagaingame} className='play-again-btn'>Play Again</button>
          </div>
        ) : (
          <p>No scores available.</p>
        )}
      </div>
    </div>
  );
};

export default Leaderboard;
