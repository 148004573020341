
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

const TimerContainer = styled.div`
  width: 100px;
  height: 100px;
  position: relative;
  margin: 0px auto;
`;

const TimerText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 35px;
  font-weight: bold;
  color: #FFD700;
`;

const TimerSVG = styled.svg`
  width: 100%;
  height: 100%;
  position: absolute;
  transform: rotate(-90deg);
`;

const Roundtimer = ({ remainingTime, setRemainingTime }) => {
    // const [remainingTime, setRemainingTime] = useState(60);
    const timerRef = useRef(null);
    const totalTime = 30;
    const totalDots = 20;

    useEffect(() => {
        startTimer();
        return () => clearInterval(timerRef.current);
    }, []);

    const startTimer = () => {
        timerRef.current = setInterval(() => {
            setRemainingTime(prevTime => {
                if (prevTime <= 1) {
                    clearInterval(timerRef.current);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);
    };

    const createDots = () => {
        const dots = [];
        for (let i = 0; i < totalDots; i++) {
            const angle = (i / totalDots) * Math.PI * 2;
            const x = 50 + 45 * Math.cos(angle);
            const y = 50 + 45 * Math.sin(angle);
            const filledDots = Math.floor((totalTime - remainingTime) / (totalTime / totalDots));
            dots.push(
                <circle
                    key={i}
                    cx={x}
                    cy={y}
                    r="3"
                    fill="#0000FF"
                    fillOpacity={i < filledDots ? 0 : 1}
                />
            );
        }
        return dots;
    };

    return (
        <TimerContainer>
            <TimerSVG viewBox="0 0 100 100">
                {createDots()}
            </TimerSVG>
            <TimerText>{remainingTime}</TimerText>
        </TimerContainer>
    );
};

export default Roundtimer;
