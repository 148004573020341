import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Home from "../views/Home/Home";
import Gameplay from '../views/Gameplay/Gameplay'
import Result from "../views/Result/Result";
import Leaderboard from "../views/Leaderboard/Leaderboard";
import Test from "../views/Test/Test";
const Routers = () => {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/gameplay' element={<Gameplay />} />
        <Route path='/result' element={<Result />} />
        <Route path='/leaderboard' element={<Leaderboard />} />
        <Route path="/test" element={<Test />} />
      </Routes>
    </Router>
  )

}

export default Routers